import React from "react"
import { graphql, Link } from "gatsby"
import BlikiBrief from "../components/BlikiBrief"
import Layout from "../components/Layout"

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    blikis: allFile(
      filter: {
        sourceInstanceName: { eq: "bliki" }
        extension: { eq: "mdx" }
        childMdx: {
          fields: { show: { eq: true } }
          frontmatter: { type: { eq: "note" } }
        }
      }
      sort: { order: DESC, fields: childMdx___frontmatter___last_updated_on }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          extension
          childMdx {
            frontmatter {
              title
              last_updated_on(formatString: "MMM DD, YYYY")
              tags
              type
              slug
            }
          }
        }
      }
    }
  }
`

const BlikiList = ({ data, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/notes/` : `/notes/${(currentPage - 1).toString()}`
  const nextPage = `/notes/${(currentPage + 1).toString()}`

  return (
    <Layout>
      <section className="container lg:max-w-screen-lg mx-auto mt-10 px-4 pt-24">
        <div className="flex items-baseline justify-between border-b-2 border-primary-400 mb-10">
          <h2 className="font-bold text-grey-700 py-4 -mb-0.5">All Notes</h2>
        </div>
        {data.blikis.edges.map(({ node }, index) => {
          return (
            <BlikiBrief
              key={index}
              title={node.childMdx.frontmatter.title}
              slug={node.childMdx.frontmatter.slug}
              tags={node.childMdx.frontmatter.tags}
              type={node.childMdx.frontmatter.type}
              last_updated_on={node.childMdx.frontmatter.last_updated_on}
            />
          )
        })}

        <div className="flex justify-between pt-8">
          {!isFirst && (
            <Link to={prevPage} rel="prev" className="text-primary-500 text-lg font-header font-semibold">
              ← Newer
            </Link>
          )}

          {/* Dummy placeholder for first page */}
          {isFirst && (<span></span>)}

          {!isLast && (
            <Link to={nextPage} rel="next" className="text-primary-500 text-lg font-header font-semibold">
              Older →
            </Link>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default BlikiList
